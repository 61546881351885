/*
* App Global CSS
* ----------------------------------------------------------------------------
* Put style rules here that you want to apply globally. These styles are for
* the entire app and not just one component. Additionally, this file can be
* used as an entry point to import other CSS/Sass files to be included in the
* output CSS.
* For more information on global stylesheets, visit the documentation:
* https://ionicframework.com/docs/layout/global-stylesheets
*/

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
//@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
//@import "~@ionic/angular/css/text-transformation.css";
//@import "~@ionic/angular/css/flex-utils.css";

@import "styles/helpers";
@import "styles/typography";
@import "styles/detail";
@import "styles/mixins";

a {
  text-decoration: none;
}

ion-toolbar {
  --background: #006AB3;
}

ion-toolbar, ion-back-button {
  color: #FFF;
}

ion-content {
  --padding-bottom: calc(2 * max(var(--ion-safe-area-bottom), 1rem) + 2rem);
  line-height: 1.25;
}

.header-logo {
  width: 195px;
  max-width: 100%;
  display: inline-block;
  vertical-align: middle;
}

.mn-action-sheet {
  --backdrop-opacity: .4;
  --ion-backdrop-color: var(--ion-color-primary);

  .action-sheet-button-inner.sc-ion-action-sheet-ios {
    justify-content: flex-start;
  }

  .action-sheet-button.sc-ion-action-sheet-md.action-sheet-cancel {
    border-top: 1px solid #{$gray-500};
  }
}

ion-chip {
  margin-left: 0;
  margin-right: .5rem;

  @media (prefers-color-scheme: dark) {
    color: var(--ion-color-dark);
    border-color: var(--ion-color-dark);
  }
}

.filter-head {
  font-size: 1.25rem;
  font-weight: bold;
}

.ripple-parent {
  position: relative;
  overflow: hidden;
}

.ion-popover--multiline .alert-checkbox-label {
  text-overflow: unset;
  white-space: normal;
  padding-top: 0;
  padding-bottom: 0;
  @include lineClamp($line-height, $label-text-max-lines);
}

/**
 * Disable features that are not supported in the current fidion native app wrapper
 *
 * @todo: undo changes once we update to the new fidion native app wrapper
 */
#social-share-button, #route-planen-button {
  display: none;
}
