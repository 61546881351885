@import "../theme/variables";

.text-muted {
  color: #{$gray-500};
}

.text-semibold {
  font-weight: 600;
}

.text-expandable {
  overflow: hidden;

  &--has-more {
    mask-image: linear-gradient(180deg, var(--background) 50%, transparent);
  }
}

.align-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.align-middle {
  display: inline-block;
  vertical-align: middle
}

.flex-space {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
