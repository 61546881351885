.detail {
  margin: 1rem 1rem 0;

  &:not(:last-child) {
    margin-bottom: 1rem;
    border-bottom: $border-md;
    border-color: $gray-200;
  }

  &__infos {
    margin: 0;

    dt {
      font-weight: bold;
    }

    dd {
      line-height: 1.5;
      margin: 0 0 var(--margin-bottom, 1rem) 0;
    }
  }
}
