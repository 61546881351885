@import "../theme/variables";

@mixin lineClamp($line-height, $max-lines) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: $max-lines;
  max-height: $line-height * $max-lines * 1em;
  line-height: $line-height;

  @if($max-lines == 1) {
    word-break: break-all;
  }
}
