@import "../theme/variables";

.title {
  font-size: 1.5rem;
  color: var(--ion-text-color);
  margin: 0 0 var(--margin-bottom, 1rem) 0;
}

.subheader {
  margin: 0 0 .5rem;
}

/**
 * Manually Copied Partials From MEFEC
 * @todo: Import appropriate files from me-frontend-components dependency
 */
%h1-6 {
  margin: 2.5rem 0 1.5rem;
  padding: 0;
  text-transform: uppercase;
  display: inline-block;
  font-weight: bold;
  color: $primary;
}

h1 {
  @extend %h1-6;
  font-size: $h1-font-size;
}

h2 {
  @extend %h1-6;
  font-size: $h2-font-size;
}

h3 {
  @extend %h1-6;
  font-size: $h3-font-size;

}

h6 {
  @extend %h1-6;
  font-size: $h6-font-size;
  border-bottom: 0;
  margin: 0;
}

.me-header {
  color: $primary;
  margin-bottom: 1.5rem;
  border-bottom: $border-sm;
  border-color: initial;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  h1, h2 {
    margin-bottom: 0;
    border-bottom: $border-lg;
    border-color: initial;
  }
}

/**
 * Hyphenation
 *
 * In addition to »Thoughts From Eric« we don't hyphenate anchor tags; instead we simply word break these …
 *
 * @see https://meyerweb.com/eric/thoughts/2012/12/17/where-to-avoid-css-hyphenation/
 */
body {
  hyphens: auto;
}

code, var, kbd, samp, tt, dir, listing, plaintext, xmp, abbr, acronym, blockquote, q, a {
  hyphens: none;
}

a {
  word-break: break-all;
}
